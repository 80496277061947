import { match as matchLocale } from "@formatjs/intl-localematcher";
import Negotiator from "negotiator";

import { i18n } from "./i18n.config";

import type { Locale } from "./i18n.config";

export function enforceLocaleInPath(request: Request): Response | undefined {
	const url = new URL(request.url);
	const pathname = url.pathname;

	const langQuery = url.searchParams.get("lang");

	const hasLocaleInPathname = i18n.locales.some(
		(locale) => pathname.startsWith(`/${locale}/`) || pathname === `/${locale}`,
	);

	if (!hasLocaleInPathname && !langQuery) {
		const locale = getLocale(request);
		return Response.redirect(
			new URL(`/${locale}${pathname.startsWith("/") ? "" : "/"}${pathname}${url.search}`, request.url),
		);
	}

	if (!hasLocaleInPathname && langQuery) {
		const locale = getLocale(request);
		return Response.redirect(
			new URL(`/${locale}${pathname.startsWith("/") ? "" : "/"}${pathname}${url.search}`, request.url),
		);
	}

	if (hasLocaleInPathname && langQuery) {
		const locale = checkLang(langQuery);
		const pathnameWithoutLocale = cleanPathname(pathname);
		return Response.redirect(
			new URL(`/${locale}${pathname.startsWith("/") ? "" : "/"}${pathnameWithoutLocale}`, request.url),
		);
	}

	// If none of the conditions are met, return undefined
	return undefined;
}

function getLocale(request: Request): string | undefined {
	let languages;

	// Non-browser based calls may not have accept-language headers defined
	if (request.headers.get("accept-language")) {
		const negotiatorHeaders: Record<string, string> = {};
		request.headers.forEach((value, key) => (negotiatorHeaders[key] = value));
		languages = new Negotiator({ headers: negotiatorHeaders }).languages();
	} else {
		// set default language
		languages = [i18n.defaultLocale];
	}

	return matchLocale(languages, i18n.locales, i18n.defaultLocale);
}

function checkLang(lang: string): string {
	const locale = i18n.locales.includes(lang.toLowerCase() as Locale);
	if (locale) {
		return lang.toLowerCase();
	} else {
		return "en";
	}
}

export function cleanPathname(pathname: string): string {
	let cleanPath = pathname;
	i18n.locales.forEach((locale) => {
		if (cleanPath.startsWith(`/${locale}/`)) {
			cleanPath = cleanPath.replace(`/${locale}/`, "/");
		}
		if (cleanPath === `/${locale}`) {
			cleanPath = cleanPath.replace(`/${locale}`, "/");
		}
	});
	return cleanPath;
}
