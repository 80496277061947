import React from "react";

export default function TrackingJs(): React.JSX.Element {
	return (
		<div
			dangerouslySetInnerHTML={{
				__html: `
                  <script>
                  window._epicEnableCookieGuard = true;
                  window.__tracking_base = "https://tracking.rocketleague.com";
                  </script>
                  <script src="https://tracking.rocketleague.com/tracking.js" async></script>
                  `,
			}}
		/>
	);
}
