import { cleanPathname } from "~/i18n";
import { i18n } from "~/i18n.config";

interface Props {
	pathname: string;
}

function canonicalUrl(pathname: string, locale: string): string {
	return `/${locale}${cleanPathname(pathname)}`;
}

export function AlternateHrefLang({ pathname }: Props): React.ReactNode {
	return (
		<>
			<link rel="alternate" href={canonicalUrl(pathname, i18n.defaultLocale)} hrefLang="x-default" />
			{i18n.locales.map((locale) => (
				<link key={locale} rel="alternate" href={canonicalUrl(pathname, locale)} hrefLang={locale} />
			))}
		</>
	);
}
