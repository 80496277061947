import { useEffect, useRef } from "react";

import data from "./octane_loader.json";

export default function LoadingAnimation(): React.ReactNode {
	const containerRef = useRef<HTMLDivElement>(null);

	// Loading lottie in the useEffect as it's not compatible with SSR
	useEffect(() => {
		async function loadLottie(): Promise<(() => void) | undefined> {
			if (!containerRef.current) return;

			const lottie = await import("lottie-web");
			const animation = lottie.default.loadAnimation({
				container: containerRef.current,
				renderer: "svg",
				loop: true,
				autoplay: true,
				animationData: data,
			});

			return () => animation.destroy();
		}

		loadLottie();
	});

	return (
		<div className="container is-max-desktop">
			<div className="columns is-mobile is-centered mt-6">
				<div className="column is-two-fifths">
					<div className="loaderWrap my-6">
						<div ref={containerRef} style={{ height: "200px", width: "200px" }} />
					</div>
				</div>
			</div>
		</div>
	);
}
