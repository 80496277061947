import { Link } from "@remix-run/react";

export function NotFound(): React.ReactNode {
	return (
		<div className="container is-widescreen mt-6 pt-6">
			<div className="columns is-5-mobile is-4-tablet is-3-desktop is-2-widescreen is-2-fullhd is-multiline is-mobile is-vcentered is-centered">
				<div className="column is-full-mobile is-half-tablet is-one-third-desktop">
					<h3 className="title">Whatever you&apos;re looking for has either moved or no longer exists.</h3>
					<p className="subtitle">
						Check out the latest <Link to="http://sideswipe.rocketleague.com/news/">Rocket League Sideswipe news</Link>{" "}
						or head to the <Link to="http://sideswipe.rocketleague.com/">homepage</Link>.
					</p>
				</div>
				<div className="column is-full-mobile is-half-tablet is-one-third-desktop">
					<img src="/images/rl_404-page_octane.png" width={960} height={540} alt="Flipped Octane 404 Image" />
				</div>
			</div>
		</div>
	);
}
