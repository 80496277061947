import { json } from "@remix-run/cloudflare";
import {
	isRouteErrorResponse,
	useRouteError,
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLoaderData,
	useLocation,
	useNavigation,
	useParams,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";

import { NotFound } from "~/components/NotFound";

import bulma from "./bulma.css?url";
import { AlternateHrefLang } from "./components/AlternateHrefLang";
import LoadingAnimation from "./components/LoadingAnimation";
import OneTrustBanner from "./components/OneTrust";
import TrackingJs from "./components/TrackingJs";
import { getSdk } from "./generated/schema.graphcms";
import global from "./globals.scss?url";
import GlobalContextProvider from "./routes/$lang._index/components/context/GlobalContext";
import { Footer } from "./routes/$lang._index/components/footer/Footer";
import Navbar from "./routes/$lang._index/components/header/Navbar";
import { getGraphCms } from "./services/graphcms";
import typography from "./typography.css?url";
import { getDictionary } from "./utils/dictionaries";
import { localeTokenMap } from "./utils/localeTokenMap";

import type { GetNavigationQuery, Locale } from "./generated/schema.graphcms";
import type { Dictionary } from "./utils/dictionaries";
import type { LoaderFunctionArgs, LinksFunction } from "@remix-run/cloudflare";
// import LoadingAnimation from "./components/LoadingAnimation";

export const links: LinksFunction = () => [
	{ rel: "stylesheet", href: bulma },
	{ rel: "stylesheet", href: global },
	{ rel: "stylesheet", href: typography },
];

export function Layout({ children }: { children: React.ReactNode }): React.ReactNode {
	const { pathname } = useLocation();
	const { epic_components_domain } = useLoaderData<typeof loader>();
	return (
		<html lang="en">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<Meta />
				<Links />
				<AlternateHrefLang pathname={pathname} />
				<script
					type="module"
					crossOrigin="anonymous"
					src={`${epic_components_domain}/footer/sideswipe-footer.mjs`}
				></script>
			</head>
			<body className={`rl-sideswipe max-width`} style={{ overflowX: "hidden" }}>
				{children}
				<ScrollRestoration />
				<Scripts />
			</body>
		</html>
	);
}

function App(): React.ReactNode {
	const { navdata, epic_hostname } = useLoaderData<typeof loader>();
	const { lang: locale = "en" } = useParams();
	const { state } = useNavigation();

	return (
		<GlobalContextProvider locale={locale as Locale}>
			<Navbar config={navdata?.header} />
			{state === "loading" ? <LoadingAnimation /> : <Outlet />}
			<Footer locale={locale as Locale} domain={epic_hostname} />
			<TrackingJs />
			<OneTrustBanner />
		</GlobalContextProvider>
	);
}

export default withSentry(App);

async function getNavData(env: Env, mappedLocaleToken: string): Promise<GetNavigationQuery> {
	const { GetNavigation } = getSdk(getGraphCms(env));

	const globalPageData = await GetNavigation({
		locale: [mappedLocaleToken, "en"] as Locale[],
		appId: "sideswipe",
	});

	return globalPageData;
}

export async function loader({ params, context }: LoaderFunctionArgs) {
	const { env } = context.cloudflare;
	const epic_hostname = env.EPIC_HOSTNAME;
	const epic_components_domain = env.EPIC_COMPONENTS_DOMAIN;
	const locale = params.lang as string;
	const mappedLocaleToken = localeTokenMap(locale);

	const navdata = await getNavData(env, mappedLocaleToken);

	const dict = await getDictionary(locale);

	return json<{
		navdata: GetNavigationQuery;
		dict: Dictionary;
		epic_hostname: string;
		epic_components_domain: string;
	}>({
		navdata,
		dict,
		epic_hostname,
		epic_components_domain,
	});
}

export function ErrorBoundary(): React.ReactNode {
	const error = useRouteError();
	console.log(error);

	if (isRouteErrorResponse(error) && error.status === 404) {
		return <NotFound />;
	}

	captureRemixErrorBoundaryError(error);

	return (
		<div>
			<h1>Oops!</h1>
		</div>
	);
}
